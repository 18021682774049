import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import {
  PatientInfo,
  Province,
  UserProfile,
} from "@alethea-medical/aletheamd-types";
import { MaskitoMask, maskitoTransform } from "@maskito/core";
import { FilterOptionsState } from "@mui/material";
import Autocomplete, {
  AutocompleteRenderInputParams,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import MaskedInput from "src/components/MaskedInput";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../../AuthProvider";
import { fbFunctions } from "../../../firebase";
import hasPermissions from "../../../models/hasPermissions";
import parseJSONTimestamp from "../../../models/parseJSONTimestamp";
import sharedStyles from "../../shared/sharedStyles";
import { TypedControlledInputProps } from "../types";
import {
  getExamplePHN,
  getPHNLength,
  getPHNMaskFromProvince,
  stripPHN,
} from "./PHNUtils";
import isPHN from "./isPHN";

type PHNTextFieldProps<T extends FieldValues> = {
  field: ControllerRenderProps<T>;
  fieldState: ControllerFieldState;
  params?: AutocompleteRenderInputParams;
  mask: MaskitoMask;
  errorTextPadding?: boolean;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label?: string;
  disabled?: boolean;
};

// TODO why is it always focused
// TODO why don't rules pass all the way through from PHNInputLookup
const PHNTextField = <T extends FieldValues>({
  field,
  params,
  fieldState,
  errorTextPadding,
  mask,
  placeholder,
  onChange,
  label,
  disabled,
}: PHNTextFieldProps<T>) => {
  const strippedValue = stripPHN(field.value);
  return (
    <TextField
      {...field}
      {...params}
      InputProps={{
        ...params?.InputProps,
        inputComponent: MaskedInput,
      }}
      inputProps={{
        ...params?.inputProps,
        mask,
        name: field.name,
        value: strippedValue,
      }}
      placeholder={placeholder}
      onChange={(e) => {
        const strippedValue = stripPHN(e.target.value);
        field.onChange(strippedValue);
        if (onChange) {
          onChange({ ...e, target: { ...e.target, value: strippedValue } });
        }
      }}
      label={label}
      error={fieldState.error !== undefined}
      margin="dense"
      helperText={
        errorTextPadding
          ? fieldState.error?.message
            ? fieldState.error.message
            : " "
          : fieldState.error?.message
      }
      InputLabelProps={{
        disableAnimation: true,
      }}
      disabled={disabled}
      fullWidth
    />
  );
};

type AutocompletePHNTextFieldProps<T extends FieldValues> = {
  field: ControllerRenderProps<T>;
  fieldState: ControllerFieldState;
  className: string;
  options: PatientInfo[];
  getOptionLabel: (option: string | PatientInfo) => string;
  filterOptions: (
    options: PatientInfo[],
    state: FilterOptionsState<PatientInfo>,
  ) => PatientInfo[];
  loading: boolean;
  disabled?: boolean;
  getOptionKey: (option: string | PatientInfo) => string;
  onChange: (
    _: React.SyntheticEvent,
    value: string | PatientInfo | (string | PatientInfo)[] | null,
  ) => void;
  errorTextPadding?: boolean;
  mask: MaskitoMask;
  placeholder?: string;
  onChangeTextField: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  label?: string;
};

const AutocompletePHNTextField = <T extends FieldValues>({
  field,
  fieldState,
  className,
  options,
  getOptionLabel,
  filterOptions,
  loading,
  disabled,
  getOptionKey,
  onChange,
  errorTextPadding,
  mask,
  placeholder,
  onChangeTextField,
  label,
}: AutocompletePHNTextFieldProps<T>) => (
  <Autocomplete
    {...field}
    className={className}
    options={options}
    getOptionLabel={getOptionLabel}
    filterOptions={filterOptions}
    inputValue={field?.value ?? ""}
    value={field?.value ?? ""}
    freeSolo
    loading={loading}
    disabled={disabled}
    getOptionKey={getOptionKey}
    onChange={onChange}
    renderInput={(params) => (
      <PHNTextField
        field={field}
        fieldState={fieldState}
        params={params}
        errorTextPadding={errorTextPadding}
        mask={mask}
        placeholder={placeholder}
        onChange={onChangeTextField}
        label={label}
      />
    )}
  />
);

//#region Helper Functions

const lookupPatientInfo = fbFunctions.httpsCallable("patientInfo-lookup");

const useStyles = makeStyles()(() => {
  return {
    ...sharedStyles(),
  };
});

/**
 * Gets a unique key for an option.
 * @param option PatientInfo | string
 * @returns string
 */
const getOptionKey = (option: string | PatientInfo) => {
  if (typeof option === "string") return option;
  return JSON.stringify(option);
};

// I don't know what this function does. It can likely be removed, but it was kept from the previous
// implementation for backwards compatibility.
const checkPermissionsAndWarmupServer = async (
  onPermissionLoaded: (permission: boolean) => void,
  profile?: UserProfile,
  skipServerWarmup?: boolean,
) => {
  const allow = await hasPermissions(resourceKeys.phnLookup, profile);
  onPermissionLoaded(allow);

  if (!allow || skipServerWarmup) {
    return;
  }

  try {
    await lookupPatientInfo();
  } catch (e) {
    console.error(e);
  }
};

// #endregion

// #region Component

interface PHNInputProps<T extends FieldValues>
  extends TypedControlledInputProps<T> {
  handlePatientInfo?: (patientInfo: PatientInfo) => void;
  ignoreChecksum?: boolean;
  skipServerWarmup?: boolean;
  province?: Province;
  label?: string;
  required?: boolean;
  autocomplete?: boolean;
  rules?: Omit<
    RegisterOptions<T, Path<T>>,
    "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
}

export default function PHNInput<T extends FieldValues>({
  skipServerWarmup,
  label,
  province,
  name,
  handlePatientInfo,
  disabled,
  control,
  required,
  ignoreChecksum,
  errorTextPadding,
  autocomplete = false,
  rules,
}: PHNInputProps<T>) {
  const [patientInfoList, setPatientInfoList] = useState<PatientInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [permissionGranted, setPermissionGranted] = useState<boolean>(false);

  const authContext = useContext(AuthContext);

  const mask = useMemo(() => getPHNMaskFromProvince(province), [province]);
  const validationRules = useMemo(
    () => ({
      validate: {
        required: (phn: string) => {
          if (required) return phn === "" ? "PHN is required" : undefined;
          return undefined;
        },
        isPHN: (phn?: string) => {
          if (ignoreChecksum) return undefined;
          return isPHN(stripPHN(phn), province);
        },
      },
    }),
    [required, ignoreChecksum, province],
  );
  const handleSearchStringChange = useCallback(
    async (searchString: string) => {
      const filteredSearchString = stripPHN(searchString);
      if (filteredSearchString.length < 3) {
        setPatientInfoList([]);
        return;
      }

      if (
        (filteredSearchString.length !== 3 &&
          filteredSearchString.length !==
            Math.min(9, getPHNLength(province))) ||
        !permissionGranted
      ) {
        return;
      }

      setLoading(true);
      try {
        const res = await lookupPatientInfo({ phn: filteredSearchString });
        const parsedInfo = res.data.map((p: PatientInfo) => ({
          ...p,
          dateOfBirth: parseJSONTimestamp(p.dateOfBirth),
        }));
        setPatientInfoList(parsedInfo);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [permissionGranted, setLoading, setPatientInfoList],
  );
  const getOptionLabel = useCallback(
    (option: PatientInfo | string) => {
      if (typeof option === "string") {
        return "";
      }

      const formattedPHN = maskitoTransform(option.phn, { mask });
      if (!option.firstName && !option.lastName) {
        return `${formattedPHN}: ${option.email}`;
      }
      return `${formattedPHN}: ${option.firstName}${
        option.lastName ? ` ${option.lastName}` : ""
      }`;
    },
    [mask],
  );

  const filterOptions = createFilterOptions({
    stringify: (option: PatientInfo): string => option.phn,
  });

  useEffect(() => {
    checkPermissionsAndWarmupServer(
      setPermissionGranted,
      authContext.profile,
      skipServerWarmup,
    );
  }, [authContext.profile]);

  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules ?? validationRules}
      render={({ field, fieldState }) => {
        const onAutocompleteChange = useCallback(
          (
            _: React.SyntheticEvent,
            value: string | PatientInfo | (string | PatientInfo)[] | null,
          ) => {
            if (value === null) {
              field.onChange("");
              return;
            }
            if (typeof value === "string") {
              field.onChange(stripPHN(value));
              return;
            }
            if (!Array.isArray(value)) {
              // Else type is patient info, update PHN value and call handle patient info callback
              field.onChange(value.phn);
              if (handlePatientInfo) handlePatientInfo(value);
            }
          },
          [field, handlePatientInfo],
        );

        const onChangeTextField = (
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => {
          handleSearchStringChange(e.target.value);
          field.onChange(e.target.value);
        };

        return autocomplete ? (
          <AutocompletePHNTextField
            field={field}
            fieldState={fieldState}
            className={classes.canDisable}
            options={patientInfoList}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            loading={loading}
            disabled={disabled}
            getOptionKey={getOptionKey}
            onChange={onAutocompleteChange}
            errorTextPadding={errorTextPadding}
            placeholder={getExamplePHN(province)}
            label={label}
            mask={mask}
            onChangeTextField={onChangeTextField}
          />
        ) : (
          <PHNTextField
            field={field}
            mask={mask}
            errorTextPadding={errorTextPadding}
            fieldState={fieldState}
            placeholder={getExamplePHN(province)}
            label={label}
            onChange={(e) => {
              const unstrippedPHN = e.target.value;
              field.onChange(stripPHN(unstrippedPHN));
            }}
            disabled={disabled}
          />
        );
      }}
    />
  );
}
// #endregion
