//Update every release
//Format is x.y.z.yyyy.mm.dd

//x.y.z should match package.json version
//yyyy.mm.dd should match day of release
const versionStrings = {
  //Official release version string
  version: "11.1.1.2025.02.10",
  //Increment to keep track of development builds
  devBuild: "1",
};

export default versionStrings;
